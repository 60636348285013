export const SuggestEventFormMocks = [
  {
    name: "What’s the name of the event?",
    placeholder: "Event Name",
    type: "text",
    inputName: "eventName",
    isRequired: true,
  },
  {
    name: "Where is the event located?",
    placeholder: "Event Location",
    type: "text",
    inputName: "city",
    isRequired: true,
  },
  {
    name: "What’s the event’s website? (optional)",
    placeholder: "Event URL (e.g., jazzfestival.com)",
    type: "text",
    inputName: "eventUrl",
    isRequired: false,
  },
  {
    name: "Your email address",
    placeholder: "jordan@dreamteam.com",
    type: "email",
    inputName: "email",
    isRequired: true,
    validations: {
      pattern: {
        value:
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+\.[a-zA-Z]{2,61}$/,
        message: "Invalid email address",
      },
    },
  },
];
